import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/axiosConfig";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const NodeSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    image: Yup.string().required("Required"),
    installation_price: Yup.number().required("Required"),
    price_per_month: Yup.number().required("Required"),
    link: Yup.string().url("Invalid URL"),
    is_active: Yup.boolean().required("Required"),
    is_tba: Yup.boolean(),
});

const Nodes = () => {
    const [nodes, setNodes] = useState([]);
    const [id, setId] = useState("");
    const [search, setSearch] = useState("");
    const [open, setOpen] = useState(false);
    const [currentNode, setCurrentNode] = useState(null);

    useEffect(() => {
        fetchNodes();
    }, [id, search]);

    const fetchNodes = async () => {
        try {
            const response = await axiosInstance.get("/admin/all-nodes", {
                params: { id, search },
            });
            setNodes(response.data);
        } catch (error) {
            console.error("Error fetching nodes:", error);
        }
    };

    const handleOpen = (node = null) => {
        setCurrentNode(node);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentNode(null);
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            if (currentNode) {
                await axiosInstance.put(
                    `/admin/update-node/${currentNode._id}`,
                    values
                );
            } else {
                await axiosInstance.post("/admin/new-node", values);
            }
            fetchNodes();
            handleClose();
        } catch (error) {
            console.error("Error saving node:", error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div>
            <Typography sx={{ marginBottom: "20px", fontWeight: "700" }}>
                Nodes
            </Typography>
            <Stack
                direction="row"
                sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
                <div sx={{ display: "flex" }}>
                    <TextField
                        label="Поиск по id"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        variant="outlined"
                        margin="normal"
                    />
                    <TextField
                        label="Name Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        sx={{ marginLeft: "10px" }}
                    />
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpen()}
                >
                    Create Node
                </Button>
            </Stack>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell>Installation Price</TableCell>
                            <TableCell>Price Per Month</TableCell>
                            <TableCell>Link</TableCell>
                            <TableCell>Active</TableCell>
                            <TableCell>TBA</TableCell>
                            <TableCell>With keys only</TableCell>
                            <TableCell>With new only</TableCell>
                            <TableCell>Bundle</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {nodes.map((node) => (
                            <TableRow key={node._id}>
                                <TableCell>{node._id}</TableCell>
                                <TableCell>{node.name}</TableCell>
                                <TableCell>{node.image}</TableCell>
                                <TableCell>{node.installation_price}</TableCell>
                                <TableCell>{node.price_per_month}</TableCell>
                                <TableCell>{node.link}</TableCell>
                                <TableCell>
                                    {node.is_active ? "Yes" : "No"}
                                </TableCell>
                                <TableCell>
                                    {node.is_tba ? "Yes" : "No"}
                                </TableCell>
                                <TableCell>
                                    {node.with_keys_only ? "Yes" : "No"}
                                </TableCell>
                                <TableCell>
                                    {node.with_new_only ? "Yes" : "No"}
                                </TableCell>
                                <TableCell>
                                    {node.is_bundle ? "Yes" : "No"}
                                </TableCell>
                                <TableCell>
                                    <div style={{ display: "flex" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleOpen(node)}
                                            sx={{ marginRight: "15px" }}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                        >
                                            <Link
                                                to={`/nodedetails/${node._id}`}
                                                style={{
                                                    color: "white",
                                                    textDecoration: "none",
                                                }}
                                            >
                                                Edit Details
                                            </Link>
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    {currentNode ? "Edit Node" : "Create Node"}
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={{
                            name: currentNode ? currentNode.name : "",
                            image: currentNode ? currentNode.image : "",
                            installation_price: currentNode
                                ? currentNode.installation_price
                                : "",
                            price_per_month: currentNode
                                ? currentNode.price_per_month
                                : "",
                            link: currentNode ? currentNode.link : "",
                            is_active: currentNode
                                ? currentNode.is_active
                                : false,
                            is_tba: currentNode ? currentNode.is_tba : false,
                            with_keys_only: currentNode
                                ? currentNode.with_keys_only
                                : false,
                            with_new_only: currentNode
                                ? currentNode.with_new_only
                                : false,
                            is_bundle: currentNode
                                ? currentNode.is_bundle
                                : false,
                        }}
                        validationSchema={NodeSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <Field
                                    name="name"
                                    as={TextField}
                                    label="Name"
                                    fullWidth
                                    margin="normal"
                                    error={touched.name && !!errors.name}
                                    helperText={touched.name && errors.name}
                                />
                                <Field
                                    name="image"
                                    as={TextField}
                                    label="Image"
                                    fullWidth
                                    margin="normal"
                                    error={touched.image && !!errors.image}
                                    helperText={touched.image && errors.image}
                                />
                                <Field
                                    name="installation_price"
                                    as={TextField}
                                    label="Installation Price"
                                    type="number"
                                    fullWidth
                                    margin="normal"
                                    error={
                                        touched.installation_price &&
                                        !!errors.installation_price
                                    }
                                    helperText={
                                        touched.installation_price &&
                                        errors.installation_price
                                    }
                                />
                                <Field
                                    name="price_per_month"
                                    as={TextField}
                                    label="Price Per Month"
                                    type="number"
                                    fullWidth
                                    margin="normal"
                                    error={
                                        touched.price_per_month &&
                                        !!errors.price_per_month
                                    }
                                    helperText={
                                        touched.price_per_month &&
                                        errors.price_per_month
                                    }
                                />
                                <Field
                                    name="link"
                                    as={TextField}
                                    label="Link"
                                    fullWidth
                                    margin="normal"
                                    error={touched.link && !!errors.link}
                                    helperText={touched.link && errors.link}
                                />
                                <Field name="is_active">
                                    {({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={field.value}
                                                />
                                            }
                                            label="Active"
                                        />
                                    )}
                                </Field>
                                <Field name="is_tba">
                                    {({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={field.value}
                                                />
                                            }
                                            label="TBA"
                                        />
                                    )}
                                </Field>
                                <Field name="with_keys_only">
                                    {({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={field.value}
                                                />
                                            }
                                            label="With keys only"
                                        />
                                    )}
                                </Field>
                                <Field name="with_new_only">
                                    {({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={field.value}
                                                />
                                            }
                                            label="With new only"
                                        />
                                    )}
                                </Field>
                                <Field name="is_bundle">
                                    {({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={field.value}
                                                />
                                            }
                                            label="Bundle"
                                        />
                                    )}
                                </Field>
                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        color="primary"
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" color="primary">
                                        Save
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Nodes;
