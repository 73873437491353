import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosConfig";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Pagination,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const AllUserNodes = () => {
    const [nodes, setNodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState("");
    const [user, setUser] = useState("");
    const [id, setId] = useState("");
    const [status, setStatus] = useState("");
    const [deployType, setDeployType] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [open, setOpen] = useState(false);
    const [selectedNode, setSelectedNode] = useState(null);
    const [editData, setEditData] = useState({});
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [nodeToDelete, setNodeToDelete] = useState(null);

    const fetchAllUserNodes = async () => {
        try {
            const response = await axiosInstance.get("/admin/all-user-nodes", {
                params: {
                    id,
                    search,
                    user,
                    status,
                    deployType,
                    startDate: startDate ? startDate.toISOString() : "",
                    endDate: endDate ? endDate.toISOString() : "",
                    page,
                },
            });
            setNodes(response.data.nodes);
            setTotalPages(response.data.pages);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = (node) => {
        setSelectedNode(node);
        setEditData(node);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedNode(null);
    };

    const handleSave = async () => {
        try {
            await axiosInstance.put(
                `/admin/edit-user-node/${selectedNode._id}`,
                editData
            );
            fetchAllUserNodes();
            handleClose();
        } catch (error) {
            console.error("Error saving node:", error);
        }
    };

    const handleDeleteClick = (node) => {
        setNodeToDelete(node);
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setNodeToDelete(null);
    };

    const handleDeleteConfirm = async () => {
        try {
            await axiosInstance.delete(
                `/admin/delete-user-node/${nodeToDelete._id}`
            );
            fetchAllUserNodes();
            handleDeleteClose();
        } catch (error) {
            console.error("Error deleting node:", error);
        }
    };

    useEffect(() => {
        fetchAllUserNodes();
    }, [search, user, status, deployType, startDate, endDate, page, id]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">Ошибка: {error.message}</Typography>;
    }

    return (
        <div>
            <Typography sx={{ marginBottom: "20px", fontWeight: "700" }}>
                All Users Nodes
            </Typography>
            <div style={{ marginBottom: "20px" }}>
                <TextField
                    label="Поиск по id"
                    value={id}
                    onChange={(e) => {
                        setPage(1);
                        setId(e.target.value);
                    }}
                    style={{ marginRight: "10px" }}
                />
                <TextField
                    label="Поиск по названию"
                    value={search}
                    onChange={(e) => {
                        setPage(1);
                        setSearch(e.target.value);
                    }}
                    style={{ marginRight: "10px" }}
                />
                <TextField
                    label="Поиск по пользователю"
                    value={user}
                    onChange={(e) => {
                        setPage(1);
                        setUser(e.target.value);
                    }}
                    style={{ marginRight: "10px" }}
                />
                <FormControl style={{ marginRight: "10px", minWidth: 120 }}>
                    <InputLabel>Статус</InputLabel>
                    <Select
                        value={status}
                        onChange={(e) => {
                            setPage(1);
                            setStatus(e.target.value);
                        }}
                    >
                        <MenuItem value="">
                            <em>Все</em>
                        </MenuItem>
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="installed">Installed</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ marginRight: "10px", minWidth: 120 }}>
                    <InputLabel>Тип установки</InputLabel>
                    <Select
                        value={deployType}
                        onChange={(e) => {
                            setPage(1);
                            setDeployType(e.target.value);
                        }}
                    >
                        <MenuItem value="">
                            <em>Все</em>
                        </MenuItem>
                        <MenuItem value="new">New</MenuItem>
                        <MenuItem value="keys">With keys</MenuItem>
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Дата начала"
                        value={startDate}
                        onChange={(newValue) => {
                            setPage(1);
                            setStartDate(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ marginRight: "10px" }}
                            />
                        )}
                    />
                    <DatePicker
                        label="Дата окончания"
                        value={endDate}
                        onChange={(newValue) => {
                            setPage(1);
                            setEndDate(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ marginRight: "10px" }}
                            />
                        )}
                    />
                </LocalizationProvider>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Имя</TableCell>
                            <TableCell>Изображение</TableCell>
                            <TableCell>Цена установки</TableCell>
                            <TableCell>Цена за месяц</TableCell>
                            <TableCell>Ссылка</TableCell>
                            <TableCell>Дата установки</TableCell>
                            <TableCell>Дата окончания</TableCell>
                            <TableCell>Тип деплоя</TableCell>
                            <TableCell>Email пользователя</TableCell>
                            <TableCell>Статус</TableCell>
                            <TableCell>Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {nodes.map((node) => (
                            <TableRow key={node._id}>
                                <TableCell>{node._id}</TableCell>
                                <TableCell>{node.name}</TableCell>
                                <TableCell>
                                    <img
                                        src={node.image}
                                        alt={node.name}
                                        width="50"
                                    />
                                </TableCell>
                                <TableCell>{node.installation_price}</TableCell>
                                <TableCell>{node.price_per_month}</TableCell>
                                <TableCell>
                                    <a
                                        href={node.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {node.link}
                                    </a>
                                </TableCell>
                                <TableCell>
                                    {new Date(node.date).toLocaleDateString()}
                                </TableCell>
                                <TableCell>
                                    {new Date(
                                        node.endDate
                                    ).toLocaleDateString()}
                                </TableCell>
                                <TableCell>{node.deployType}</TableCell>
                                <TableCell>{node.user?.email}</TableCell>
                                <TableCell>{node.status}</TableCell>
                                <TableCell sx={{display: 'flex'}}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleEditClick(node)}
                                    >
                                        Редактировать
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleDeleteClick(node)}
                                        style={{ marginLeft: "10px" }}
                                    >
                                        Удалить
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => setPage(value)}
                style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                }}
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Редактировать ноду</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Внесите изменения и нажмите "Сохранить".
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Имя"
                        fullWidth
                        value={editData.name}
                        onChange={(e) =>
                            setEditData({ ...editData, name: e.target.value })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Изображение"
                        fullWidth
                        value={editData.image}
                        onChange={(e) =>
                            setEditData({ ...editData, image: e.target.value })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Цена установки"
                        fullWidth
                        value={editData.installation_price}
                        onChange={(e) =>
                            setEditData({
                                ...editData,
                                installation_price: e.target.value,
                            })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Цена за месяц"
                        fullWidth
                        value={editData.price_per_month}
                        onChange={(e) =>
                            setEditData({
                                ...editData,
                                price_per_month: e.target.value,
                            })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Ссылка"
                        fullWidth
                        value={editData.link}
                        onChange={(e) =>
                            setEditData({ ...editData, link: e.target.value })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Дата установки"
                        fullWidth
                        type="date"
                        value={
                            editData.date
                                ? new Date(editData.date)
                                      .toISOString()
                                      .substr(0, 10)
                                : ""
                        }
                        onChange={(e) =>
                            setEditData({
                                ...editData,
                                date: new Date(e.target.value),
                            })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Дата окончания"
                        fullWidth
                        type="date"
                        value={
                            editData.endDate
                                ? new Date(editData.endDate)
                                      .toISOString()
                                      .substr(0, 10)
                                : ""
                        }
                        onChange={(e) =>
                            setEditData({
                                ...editData,
                                endDate: new Date(e.target.value),
                            })
                        }
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Тип деплоя</InputLabel>
                        <Select
                            value={editData.deployType}
                            onChange={(e) =>
                                setEditData({
                                    ...editData,
                                    deployType: e.target.value,
                                })
                            }
                        >
                            <MenuItem value="new">New</MenuItem>
                            <MenuItem value="keys">With keys</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Статус</InputLabel>
                        <Select
                            value={editData.status}
                            onChange={(e) =>
                                setEditData({
                                    ...editData,
                                    status: e.target.value,
                                })
                            }
                        >
                            <MenuItem value="pending">Pending</MenuItem>
                            <MenuItem value="installed">Installed</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Промо-код"
                        fullWidth
                        value={editData.promoCode}
                        onChange={(e) =>
                            setEditData({
                                ...editData,
                                promoCode: e.target.value,
                            })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Ключи"
                        fullWidth
                        value={editData.keys}
                        onChange={(e) =>
                            setEditData({ ...editData, keys: e.target.value })
                        }
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Bundle</InputLabel>
                        <Checkbox
                            onChange={(e) => setEditData({...editData, is_bundle: e.target.checked})}
                            checked={editData.is_bundle}
                    />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    <Button onClick={handleSave}>Сохранить</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteOpen} onClose={handleDeleteClose}>
                <DialogTitle>Подтвердите удаление</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы уверены, что хотите удалить ноду {nodeToDelete?.name}
                        ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose}>Отмена</Button>
                    <Button onClick={handleDeleteConfirm} color="secondary">
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AllUserNodes;
