import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/axiosConfig";
import { Select, MenuItem, Button } from "@mui/material";

const Merchant = () => {
    const [activeMerchant, setActiveMerchant] = useState("");

    useEffect(() => {
        const fetchActiveMerchant = async () => {
            const response = await axiosInstance.get("/merchant/active");
            setActiveMerchant(response.data.activeMerchant);
        };
        fetchActiveMerchant();
    }, []);

    const handleChange = (event) => {
        setActiveMerchant(event.target.value);
    };

    const handleSave = async () => {
        await axiosInstance.post("/merchant/active", { activeMerchant });
    };

    return (
        <div>
            <Select value={activeMerchant} onChange={handleChange}>
                <MenuItem value="cryptomus">Cryptomus</MenuItem>
                <MenuItem value="plisio">Plisio</MenuItem>
            </Select>
            <Button onClick={handleSave}>Save</Button>
        </div>
    );
};

export default Merchant;
