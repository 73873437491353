import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { login } from "../services/authService";

const Login = () => {
    const navigate = useNavigate();

    return (
        <div>
            <h2>Login</h2>
            <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email("Invalid email address")
                        .required("Required"),
                    password: Yup.string().required("Required"),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        await login(values.email, values.password);
                        navigate("/");
                    } catch (ex) {
                        alert("Invalid email or password");
                        setSubmitting(false);
                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <Field name="email" type="email" placeholder="Email" />
                        <Field
                            name="password"
                            type="password"
                            placeholder="Password"
                        />
                        <button type="submit" disabled={isSubmitting}>
                            Login
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default Login;
