import React, { useState, useEffect, useCallback } from "react";
import axiosInstance from "../utils/axiosConfig";
import { TextField, Button, Grid } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams, useNavigate } from "react-router-dom";

const NodeDetails = () => {
    const [formData, setFormData] = useState({
        type: "",
        site_link: "",
        twitter_link: "",
        github_link: "",
        discord_link: "",
        telegram_link: "",
        guide_link: "",
    });

    const [description, setDescription] = useState("");
    const [bundlePack, setBundlePack] = useState("");
    const [fundsraised, setFundsraised] = useState("");
    const { nodeId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (nodeId) {
            const fetchNodeDetails = async () => {
                try {
                    const response = await axiosInstance.get(
                        `/nodeDetails/${nodeId}`
                    );
                    if (response.data) {
                        setFormData({
                            type: response.data.type,
                            site_link: response.data.site_link,
                            twitter_link: response.data.twitter_link,
                            github_link: response.data.github_link,
                            discord_link: response.data.discord_link,
                            telegram_link: response.data.telegram_link,
                            guide_link: response.data.guide_link,
                            _id: response.data._id
                        });
                        setDescription(response.data.description);
                        setBundlePack(response.data.bundle_pack);
                        setFundsraised(response.data.fundsraised);
                    }
                } catch (error) {
                    console.error("Error fetching node details:", error);
                }
            };

            fetchNodeDetails();
        }
    }, [nodeId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleQuillChange = (setter) => (value) => {
        setter(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSubmit = {
                ...formData,
                description,
                bundle_pack: bundlePack,
                fundsraised,
            };

            if (formData._id) {
                await axiosInstance.patch(
                    `/nodeDetails/${formData._id}`,
                    dataToSubmit
                );
            } else {
                await axiosInstance.post("/nodeDetails", {
                    ...dataToSubmit,
                    node: nodeId,
                });
            }
            navigate("/nodes");
        } catch (error) {
            console.error("Error saving node details:", error);
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ReactQuill
                        value={description || ""}
                        onChange={handleQuillChange(setDescription)}
                        placeholder="Description"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="type"
                        label="Type"
                        value={formData.type || ""}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactQuill
                        value={bundlePack || ""}
                        onChange={handleQuillChange(setBundlePack)}
                        placeholder="Bundle pack"
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactQuill
                        value={fundsraised || ""}
                        onChange={handleQuillChange(setFundsraised)}
                        placeholder="Fundsraised"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="site_link"
                        label="Site Link"
                        value={formData.site_link || ""}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="twitter_link"
                        label="Twitter Link"
                        value={formData.twitter_link || ""}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="github_link"
                        label="GitHub Link"
                        value={formData.github_link || ""}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="discord_link"
                        label="Discord Link"
                        value={formData.discord_link || ""}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="telegram_link"
                        label="Telegram Link"
                        value={formData.telegram_link || ""}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="guide_link"
                        label="Guide Link"
                        value={formData.guide_link || ""}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary">
                        Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default NodeDetails;
