import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/axiosConfig";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    MenuItem,
    Select,
    Autocomplete,
} from "@mui/material";

const statuses = [
    "new",
    "pending",
    "pending internal",
    "mismatch",
    "completed",
    "error",
    "expired",
    "cancelled",
    "paid",
    "paid_over",
    "wrong_amount",
    "process",
    "confirm_check",
    "wrong_amount_waiting",
    "check",
    "fail",
    "cancel",
    "system_fail",
    "locked",
];

const InvoiceTable = () => {
    const [invoices, setInvoices] = useState([]);
    const [filters, setFilters] = useState({
        invoice_id: "",
        user_email: "",
        status: "",
        dateFrom: "",
        dateTo: "",
    });

    useEffect(() => {
        fetchInvoices();
    }, [filters]);

    const fetchInvoices = async () => {
        try {
            const response = await axiosInstance.get("/admin/invoices", {
                params: filters,
            });
            setInvoices(response.data);
        } catch (error) {
            console.error("Error fetching invoices:", error);
        }
    };

    const handleChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value,
        });
    };

    const handleStatusChange = (event, value) => {
        setFilters({
            ...filters,
            status: value,
        });
    };

    return (
        <Paper>
            <div style={{ padding: "16px", display: 'flex' }}>
                <TextField
                    label="Invoice ID"
                    name="invoice_id"
                    value={filters.invoice_id}
                    onChange={handleChange}
                    style={{ marginRight: "16px" }}
                />
                <TextField
                    label="User Email"
                    name="user_email"
                    value={filters.user_email}
                    onChange={handleChange}
                    style={{ marginRight: "16px" }}
                />
                <Autocomplete
                    options={statuses}
                    getOptionLabel={(option) => option}
                    onChange={handleStatusChange}
                    style={{ marginRight: "16px", width: '300px' }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Status"
                            variant="outlined"
                            style={{ marginRight: "16px" }}
                        />
                    )}
                />
                <TextField
                    label="Date From"
                    name="dateFrom"
                    type="date"
                    value={filters.dateFrom}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginRight: "16px" }}
                />
                <TextField
                    label="Date To"
                    name="dateTo"
                    type="date"
                    value={filters.dateTo}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginRight: "16px" }}
                />
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Invoice ID</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Total Price</TableCell>
                            {/* Добавь остальные поля */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.map((invoice) => (
                            <TableRow key={invoice._id}>
                                <TableCell>{invoice.invoice_id}</TableCell>
                                <TableCell>{invoice.user?.email}</TableCell>
                                <TableCell>{invoice.status}</TableCell>
                                <TableCell>
                                    {new Date(
                                        invoice.createdAt
                                    ).toLocaleDateString()}
                                </TableCell>
                                <TableCell>{invoice.totalPrice}</TableCell>
                                {/* Добавь остальные поля */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default InvoiceTable;
