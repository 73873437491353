import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/axiosConfig";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const PromoSchema = Yup.object().shape({
    code: Yup.string().required("Required"),
    reduce_percent: Yup.number().required("Required"),
    allowed_id: Yup.string(),
    usage_number: Yup.number(),
    promoOwnerPercent: Yup.number(),
});

const Promos = () => {
    const [promos, setPromos] = useState([]);
    const [search, setSearch] = useState("");
    const [open, setOpen] = useState(false);
    const [currentPromo, setCurrentPromo] = useState(null);

    useEffect(() => {
        fetchPromos();
    }, [search]);

    const fetchPromos = async () => {
        try {
            const response = await axiosInstance.get("/admin/all-promos", {
                params: { search },
            });
            setPromos(response.data);
        } catch (error) {
            console.error("Error fetching promos:", error);
        }
    };

    const handleOpen = (promo = null) => {
        setCurrentPromo(promo);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentPromo(null);
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            if (currentPromo) {
                await axiosInstance.put(`/admin/promo/${currentPromo._id}`, {
                    ...values,
                    allowed_id:
                        values.allowed_id.length > 0
                            ? values.allowed_id.split(",")
                            : [],
                });
            } else {
                await axiosInstance.post("/admin/new-promo", {
                    ...values,
                    allowed_id:
                        values.allowed_id.length > 0
                            ? values.allowed_id.split(",")
                            : [],
                });
            }
            fetchPromos();
            handleClose();
        } catch (error) {
            console.error("Error saving promo:", error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axiosInstance.delete(`/admin/promo/${id}`);
            fetchPromos();
        } catch (error) {
            console.error("Error deleting promo:", error);
        }
    };

    return (
        <div>
            <Typography sx={{ marginBottom: "20px", fontWeight: "700" }}>
                Promos
            </Typography>
            <Stack
                direction="row"
                sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
                <TextField
                    label="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    variant="outlined"
                    margin="normal"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpen()}
                >
                    Create Promo
                </Button>
            </Stack>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Reduce Percent</TableCell>
                            <TableCell>Allowed Emails</TableCell>
                            <TableCell>Usage Number</TableCell>
                            <TableCell>Promo owner percent</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {promos.map((promo) => (
                            <TableRow key={promo._id}>
                                <TableCell>{promo.code}</TableCell>
                                <TableCell>{promo.reduce_percent}</TableCell>
                                <TableCell>
                                    {promo.allowed_id.join(", ")}
                                </TableCell>
                                <TableCell>{promo.usage_number}</TableCell>
                                <TableCell>{promo.promo_owner_percent}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleOpen(promo)}
                                        sx={{ marginRight: "10px" }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleDelete(promo._id)}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    {currentPromo ? "Edit Promo" : "Create Promo"}
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={{
                            code: currentPromo ? currentPromo.code : "",
                            reduce_percent: currentPromo
                                ? currentPromo.reduce_percent
                                : "",
                            allowed_id: currentPromo
                                ? currentPromo.allowed_id.join(",")
                                : "",
                            usage_number: currentPromo
                                ? currentPromo.usage_number
                                : "",
                            promo_owner_percent: currentPromo
                                ? currentPromo.promo_owner_percent
                                : "",
                        }}
                        validationSchema={PromoSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <Field
                                    name="code"
                                    as={TextField}
                                    label="Code"
                                    fullWidth
                                    margin="normal"
                                    error={touched.code && !!errors.code}
                                    helperText={touched.code && errors.code}
                                />
                                <Field
                                    name="reduce_percent"
                                    as={TextField}
                                    label="Reduce Percent"
                                    type="number"
                                    fullWidth
                                    margin="normal"
                                    error={
                                        touched.reduce_percent &&
                                        !!errors.reduce_percent
                                    }
                                    helperText={
                                        touched.reduce_percent &&
                                        errors.reduce_percent
                                    }
                                />
                                <Field
                                    name="allowed_id"
                                    as={TextField}
                                    label="Allowed Emails (comma separated)"
                                    fullWidth
                                    margin="normal"
                                    error={
                                        touched.allowed_id &&
                                        !!errors.allowed_id
                                    }
                                    helperText={
                                        touched.allowed_id && errors.allowed_id
                                    }
                                />
                                <Field
                                    name="usage_number"
                                    as={TextField}
                                    label="Usage Number"
                                    type="number"
                                    fullWidth
                                    margin="normal"
                                    error={
                                        touched.usage_number &&
                                        !!errors.usage_number
                                    }
                                    helperText={
                                        touched.usage_number &&
                                        errors.usage_number
                                    }
                                />
                                <Field
                                    name="promo_owner_percent"
                                    as={TextField}
                                    label="Promo Owner Percent"
                                    type="number"
                                    fullWidth
                                    margin="normal"
                                    error={
                                        touched.promo_owner_percent &&
                                        !!errors.promo_owner_percent
                                    }
                                    helperText={
                                        touched.promo_owner_percent &&
                                        errors.promo_owner_percent
                                    }
                                />
                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        color="primary"
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" color="primary">
                                        Save
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Promos;
