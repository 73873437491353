import axios from "axios";
import { jwtDecode } from "jwt-decode";

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (email, password) => {
    const response = await axios.post(`${API_URL}/auth/login`, { email, password });
    const { token } = response.data;
    localStorage.setItem("token", token);
    return jwtDecode(token);
};

export const logout = () => {
    localStorage.removeItem("token");
};

export const getCurrentUser = () => {
    try {
        const token = localStorage.getItem("token");
        return token ? jwtDecode(token) : null;
    } catch (ex) {
        return null;
    }
};

export const getJwt = () => {
    return localStorage.getItem("token");
};
